var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "Row",
        { attrs: { type: "flex" } },
        [
          _c(
            "Col",
            { attrs: { span: "4" } },
            [
              _c(
                "Menu",
                {
                  attrs: {
                    theme: _vm.theme3,
                    "active-name": _vm.openMenu,
                    width: "auto"
                  },
                  on: { "on-select": _vm.itemSelect }
                },
                [
                  _c(
                    "div",
                    { staticClass: "layout-content", style: _vm.left_content },
                    [
                      _c(
                        "MenuGroup",
                        { attrs: { title: "用户组" } },
                        _vm._l(_vm.groups, function(item) {
                          return _c(
                            "MenuItem",
                            { key: item._id, attrs: { name: item.name } },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c("Col", { attrs: { span: "20" } }, [
            _c("div", { attrs: { id: "" } }, [
              _c(
                "div",
                {
                  staticClass: "search-top title",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [
                  _c(
                    "div",
                    { staticClass: "input-group-inline" },
                    [
                      _c("label", [_vm._v("用户组：")]),
                      _c("Input", {
                        staticClass: "custom-control-width",
                        attrs: {
                          disabled: _vm.edit == 0,
                          placeholder: "请输入用户组名称",
                          maxlength: 20
                        },
                        model: {
                          value: _vm.curGroup.name,
                          callback: function($$v) {
                            _vm.$set(_vm.curGroup, "name", $$v)
                          },
                          expression: "curGroup.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "input-group-inline right clearfix" },
                    [
                      _vm.edit == 0
                        ? _c(
                            "div",
                            [
                              _vm.checkAuth("user_group-add")
                                ? _c(
                                    "Button",
                                    {
                                      staticClass: "button",
                                      attrs: { slot: "extra", type: "warning" },
                                      on: { click: _vm.addGroup },
                                      slot: "extra"
                                    },
                                    [_vm._v("新增 ")]
                                  )
                                : _vm._e(),
                              _vm.checkAuth("user_group-edit")
                                ? _c(
                                    "Button",
                                    {
                                      staticClass: "button",
                                      attrs: { slot: "extra", type: "primary" },
                                      on: { click: _vm.editGroup },
                                      slot: "extra"
                                    },
                                    [_vm._v("编辑 ")]
                                  )
                                : _vm._e(),
                              _vm.checkAuth("user_group-delete")
                                ? _c(
                                    "Button",
                                    {
                                      staticClass: "button",
                                      attrs: { slot: "extra", type: "error" },
                                      on: { click: _vm.deleteGroup },
                                      slot: "extra"
                                    },
                                    [_vm._v("删除 ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.edit == 1 || _vm.edit == 2
                        ? _c(
                            "div",
                            [
                              _c(
                                "Button",
                                {
                                  staticClass: "button",
                                  attrs: { slot: "extra", type: "warning" },
                                  on: { click: _vm.save },
                                  slot: "extra"
                                },
                                [_vm._v("保存")]
                              ),
                              _c(
                                "Button",
                                {
                                  staticClass: "button",
                                  attrs: { slot: "extra" },
                                  on: { click: _vm.cancel },
                                  slot: "extra"
                                },
                                [_vm._v("取消")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "layout-content right-content",
                  style: _vm.c_style
                },
                [
                  _c(
                    "Form",
                    { attrs: { model: _vm.curGroup, "label-width": 120 } },
                    [
                      _vm._l(_vm.groupTitles, function(title, index) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.groupTitles.length != 0,
                                expression: "groupTitles.length != 0"
                              }
                            ],
                            key: index
                          },
                          [
                            _c("div", { staticClass: "title_parent" }, [
                              _c("div", { staticClass: "title_c" }, [
                                _vm._v(_vm._s(title))
                              ])
                            ]),
                            _vm._l(_vm.curGroup.authList, function(
                              item,
                              index
                            ) {
                              return [
                                (item.status == 1 && _vm.name != "admin") ||
                                _vm.name == "admin" ||
                                _vm.userGroup == "超级管理"
                                  ? _c(
                                      "div",
                                      { key: index },
                                      [
                                        title == item.parent
                                          ? _c(
                                              "FormItem",
                                              {
                                                attrs: {
                                                  label: item.name + "："
                                                }
                                              },
                                              _vm._l(item.auth, function(
                                                one,
                                                index
                                              ) {
                                                return _c(
                                                  "Checkbox",
                                                  {
                                                    key: index,
                                                    attrs: {
                                                      disabled: _vm.edit == 0,
                                                      "true-value": 1,
                                                      "false-value": 0
                                                    },
                                                    model: {
                                                      value: one.status,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          one,
                                                          "status",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "one.status"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(one.name) + " "
                                                    )
                                                  ]
                                                )
                                              }),
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      }),
                      _vm.groupTitles.length == 0
                        ? _c("div", [
                            _c("p", { staticClass: "groupTitles" }, [
                              _vm._v(_vm._s(_vm.noDataPromit))
                            ])
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }